<template>
	<el-container id="container" style="height: 3365px;" direction="vertical">
		<div class="block">
			<img src="../../../assets/image/solutionbg.png" alt="" style="width: 1920px; height: 480px;">
			<div class="text">让数据说话　用数据决策 </div>
			<div class="text1">
				公司越做越大，老板越来越累？因为你没有数据支撑！ 
			</div>
		</div>
		<div class="text2">
			正在寻找你的行业解决方案？
		</div>
		<img src="../../../assets/image/arrowdown.png" alt="" style="width: 149px; height: 29px; position: absolute; top: 728px; left: 886px;">
		<div class="block2">
			<div class="bgimg" @mouseenter="buttonEnter(1)" @mouseleave="buttonLeave(1)">
				<div v-if="!flag1">
					<div class="imgsize img1"></div>
					<div class="bgtext">
						<div class="bgtext1">阿米巴管理  >>></div>
						<div class="bgtext2">多项目核算</div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_1">
						<div class="imgtext" style="padding-top:30px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;">阿米巴经营模式：不仅需要掌握整个
						</br>公司的盈亏状况，也需要独立核算每
						</br>个业务单元的盈亏。
						</br>比如：多项目、多门店、多业务板块等。</div>
						<div class="imgtext" style="padding-top:17px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;">1、及时了解商品采购、领用、库存
						</br>及毛利情况。
						</br>2、实时反映往来款。
						</br>3、客户贡献度分析。
						</br>4、业务员绩效考核。
						</br>5、各部门绩效分析。
						</br>6、及时掌控公司整体的经营盈亏状
						</br>况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag1" @mouseenter="textflagEnter(1)">{{buttontext1}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(1)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(2)" @mouseleave="buttonLeave(2)">
				<div v-if="!flag2">
					<div class="imgsize img2"></div>
					<div class="bgtext">
						<div class="bgtext1">服务企业(合同管理) >>></div>
						<div class="bgtext2">如财税行业等</div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_2">
						<div class="imgtext" style="padding-top:50px;padding-left: 46px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 46px;">代理记账公司等服务业，且需要合同
						</br>管理功能。 </div>
						<div class="imgtext" style="padding-top:37px;padding-left: 46px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 46px;">1、服务合同到期提醒。						</br>2、服务合同执行情况：合同量、
						</br>完工量和未完工量。						</br>3、服务合同款项进度：计划收款、
						</br>实际收款和未收款项。						</br>4、实时反映往来款。						</br>5、及时掌控公司整体的经营盈亏状
						</br>况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag2" @mouseenter="textflagEnter(2)">{{buttontext2}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(2)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(3)" @mouseleave="buttonLeave(3)">
				<div v-if="!flag3">
					<div class="imgsize img3"></div>
					<div class="bgtext">
						<div class="bgtext1">多门店管理  >>></div>
						<div class="bgtext2">统一核算、独立核算</div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_3">
						<div class="imgtext" style="padding-top:39px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;">连锁店经营模式，总部实行统一管理。
						</br>既需要核算整个公司的盈亏状况，也
						</br>需要独立核算各分店的盈亏。</div>
						<div class="imgtext" style="padding-top:38px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;">1、及时了解整个公司的商品收发及
						</br>库存情况。
						</br>2、及时了解各分店的商品收发及库
						</br>存情况。
						</br>3、实时反映往来款。
						</br>4、及时掌控公司整体的经营盈亏状
						</br>况。
						</br>5、及时掌控各分店的盈亏状况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag3" @mouseenter="textflagEnter(3)">{{buttontext3}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(3)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(4)" @mouseleave="buttonLeave(4)">
				<div v-if="!flag4">
					<div class="imgsize img4"></div>
					<div class="bgtext">
						<div class="bgtext1">商贸企业  >>></div>
						<div class="bgtext2">销售订单管理、采购订单管理</div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_4">
						<div class="imgtext" style="padding-top:53px;padding-left: 35px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 35px;">有销售管理、采购管理的商贸行业。 </div>
						<div class="imgtext" style="padding-top:42px;padding-left: 35px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 35px;">1、实时查询销售订单的订货、发货、						</br>未发货的情况。						</br>2、实时查询采购订单的采购、收货、						</br>未到货的情况。						</br>3、实时反映商品的入库、出库及寄存						</br>情况。						</br>4、实时反映往来款。						</br>5、及时掌控公司整体的经营盈亏状
						</br>况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag4" @mouseenter="textflagEnter(4)">{{buttontext4}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(4)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="block2" style="top: 1411px;">
			<div class="bgimg" @mouseenter="buttonEnter(5)" @mouseleave="buttonLeave(5)">
				<div v-if="!flag5">
					<div class="imgsize img5"></div>
					<div class="bgtext">
						<div class="bgtext1">生产制造企业  >>></div>
						<div class="bgtext2"><span v-html="'\u00a0'"/></span></div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_5">
						<div class="imgtext" style="padding-top:28px;padding-left: 27px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 27px;">有生产制令单、采购管理、销售管理的
						</br>生产制造行业。</div>
						<div class="imgtext" style="padding-top:18px;padding-left: 27px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 27px;">1、实时查询采购订单的采购、收货、
						</br>未到货的情况。
						</br>2、实时查询销售订单的订货、发货、
						</br>未发货的情况。
						</br>3、实时反映每个生产制令“标准用量”
						</br>与“实际用量”之间的差异情况。
						</br>4、根据成本核算对象，准确归集和分配
						</br>“料、工、费”。
						</br>5、实时反映原材料、商品的入库、出库
						</br>及库存情况。
						</br>6、及时掌控公司整体的经营盈亏状况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag5" @mouseenter="textflagEnter(5)">{{buttontext5}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(5)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(6)" @mouseleave="buttonLeave(6)">
				<div v-if="!flag6">
					<div class="imgsize img6"></div>
					<div class="bgtext">
						<div class="bgtext1">食品加工行业  >>></div>
						<div class="bgtext2">蛋糕店、奶茶店、汉堡店等</div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_6">
						<div class="imgtext" style="padding-top:55px;padding-left: 55px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 55px;">蛋糕店、奶茶店、汉堡店等食品
						</br>加工行业。不希望投入过高成本
						</br>进行传统的成本核算，就能快速
						</br>掌握公司的经营盈亏状况。
						</div>
						<div class="imgtext" style="padding-top:34px;padding-left: 55px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 55px;">1、及时了解物料的采购、领用及
						</br>库存情况。
						</br>2、实时反映往来款。
						</br>3、及时掌控公司整体的经营盈亏
						</br>状况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag6" @mouseenter="textflagEnter(6)">{{buttontext6}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(6)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(7)" @mouseleave="buttonLeave(7)">
				<div v-if="!flag7">
					<div class="imgsize img7"></div>
					<div class="bgtext">
						<div class="bgtext1">建安行业  >>></div>
						<div class="bgtext2"><span v-html="'\u00a0'"/></span></div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_7">
						<div class="imgtext" style="padding-top:55px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;">需要独立核算每个工程或项目盈亏
						</br>的建筑安装公司。</div>
						<div class="imgtext" style="padding-top:55px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;">1、实时反映往来款。
						</br>2、及时了解建筑材料的收发及库存
						</br>情况。
						</br>3、及时掌控公司整体的经营盈亏状
						</br>况。
						</br>4、及时掌控每个工程或项目的盈亏
						</br>状况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag7" @mouseenter="textflagEnter(7)">{{buttontext7}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(7)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(8)" @mouseleave="buttonLeave(8)">
				<div v-if="!flag8">
					<div class="imgsize img8"></div>
					<div class="bgtext">
						<div class="bgtext1">旅游行业  >>></div>
						<div class="bgtext2">如旅行社等</div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_8">
						<div class="imgtext" style="padding-top:53px;padding-left:47px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left:47px;">需要独立核算每个旅游项目或线路
						</br>盈亏的旅游公司。
						</div>
						<div class="imgtext" style="padding-top:42px;padding-left:47px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left:47px;">1、实时反映往来款。
						</br>2、及时了解旅游纪念品的收发及
						</br>库存情况。
						</br>3、及时掌控公司整体的经营盈亏
						</br>状况。
						</br>4、及时掌控每个旅游项目或线路
						</br>的盈亏状况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag8" @mouseenter="textflagEnter(8)">{{buttontext8}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(8)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="block2" style="top: 2017px;">
			<div class="bgimg" @mouseenter="buttonEnter(9)" @mouseleave="buttonLeave(9)">
				<div v-if="!flag9">
					<div class="imgsize img9"></div>
					<div class="bgtext">
						<div class="bgtext1">水果零售批发  >>></div>
						<div class="bgtext2"><span v-html="'\u00a0'"/></span></div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_9">
						<div class="imgtext" style="padding-top:60px;padding-left: 49px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 49px;">有礼盒套装的水果店。不需要投入
						</br>太高成本核算礼盒成本，就能快速
						</br>掌握公司的经营盈亏状况。</div>
						<div class="imgtext" style="padding-top:50px;padding-left: 49px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 49px;">1、及时了解水果的毛利及库存数
						</br>量。
						</br>2、实时反映往来款。
						</br>3、及时掌控公司整体的经营盈亏
						</br>状况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag9" @mouseenter="textflagEnter(9)">{{buttontext9}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(9)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(10)" @mouseleave="buttonLeave(10)">
				<div v-if="!flag10">
					<div class="imgsize img10"></div>
					<div class="bgtext">
						<div class="bgtext1">教育培训行业  >>></div>
						<div class="bgtext2"><span v-html="'\u00a0'"/></span></div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_10">
						<div class="imgtext" style="padding-top:60px;padding-left: 51px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 51px;">需要独立核算每个培训项目盈亏的
						</br>培训或教育机构。</div>
						<div class="imgtext" style="padding-top:55px;padding-left: 51px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 51px;">1、及时了解教材、教学用品等的
						</br>采购、领用及库存情况。
						</br>2、实时反映往来款。
						</br>3、及时掌控公司整体的经营盈亏
						</br>状况。
						</br>4、及时掌控每个培训项目的盈亏
						</br>状况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag10" @mouseenter="textflagEnter(10)">{{buttontext10}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(10)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(11)" @mouseleave="buttonLeave(11)">
				<div v-if="!flag11">
					<div class="imgsize img11"></div>
					<div class="bgtext">
						<div class="bgtext1">运输行业  >>></div>
						<div class="bgtext2"><span v-html="'\u00a0'"/></span></div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_11">
						<div class="imgtext" style="padding-top:60px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;">运输或车辆出租行业，且需要独立
						</br>核算每辆车的盈亏状况。</div>
						<div class="imgtext" style="padding-top:55px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;">1、实时反映往来款。
						</br>2、及时掌控公司整体的经营盈亏
						</br>状况。
						</br>3、及时掌控每辆车的盈亏状况。
						</br>4、追踪某个司机的违章记录。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag11" @mouseenter="textflagEnter(11)">{{buttontext11}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(11)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(12)" @mouseleave="buttonLeave(12)">
				<div v-if="!flag12">
					<div class="imgsize img12"></div>
					<div class="bgtext">
						<div class="bgtext1">美容美发行业  >>></div>
						<div class="bgtext2">如理发店等</div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_12">
						<div class="imgtext" style="padding-top:60px;padding-left:47px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left:47px;">理发店等美容美发行业。有会员卡
						</br>充值、理发师拿计件工资等业务场
						</br>景。</div>
						<div class="imgtext" style="padding-top:50px;padding-left:47px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left:47px;">1、准确核算理发师的计件工资。
						</br>2、实时反映往来款。
						</br>3、及时掌控公司整体的经营盈亏
						</br>状况。
						</br>4、及时掌控会员卡的充值及使用
						</br>情况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag12" @mouseenter="textflagEnter(12)">{{buttontext12}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(12)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="block2" style="top: 2621px;">
			<div class="bgimg" @mouseenter="buttonEnter(13)" @mouseleave="buttonLeave(13)">
				<div v-if="!flag13">
					<div class="imgsize img13"></div>
					<div class="bgtext">
						<div class="bgtext1">餐饮行业  >>></div>
						<div class="bgtext2">如西餐厅、烧烤、火锅等</div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_13">
						<div class="imgtext" style="padding-top:58px;padding-left: 35px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 35px;">餐饮企业。不希望投入过高成本进行
						</br>传统的成本核算，就能快速掌握公司
						</br>的经营盈亏状况。</div>
						<div class="imgtext" style="padding-top:45px;padding-left: 35px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 35px;">1、及时了解调味品等的采购、领用及
						</br>库存情况。
						</br>2、实时反映往来款。
						</br>3、及时掌控公司整体的经营盈亏状况。
						</br>4、及时掌控会员卡的充值及使用情况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag13" @mouseenter="textflagEnter(13)">{{buttontext13}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(13)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(14)" @mouseleave="buttonLeave(14)">
				<div v-if="!flag14">
					<div class="imgsize img14"></div>
					<div class="bgtext">
						<div class="bgtext1">租赁行业  >>></div>
						<div class="bgtext2"><span v-html="'\u00a0'"/></span></div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_14">
						<div class="imgtext" style="padding-top:76px;padding-left: 35px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 35px;">设备及工具租赁行业，需要监管哪些
						</br>设备在仓库、哪些设备在客户处？
						</div>
						<div class="imgtext" style="padding-top:48px;padding-left: 35px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left: 35px;">1、监管租赁物在每个客户处的留存
						</br>数量。
						</br>2、实时反映往来款。
						</br>3、及时掌控公司整体的经营盈亏状
						</br>况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag14" @mouseenter="textflagEnter(14)">{{buttontext14}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(14)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(15)" @mouseleave="buttonLeave(15)">
				<div v-if="!flag15">
					<div class="imgsize img15"></div>
					<div class="bgtext">
						<div class="bgtext1">足浴按摩行业  >>></div>
						<div class="bgtext2"><span v-html="'\u00a0'"/></span></div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_15">
						<div class="imgtext" style="padding-top:67px;padding-left:45px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left:45px;">足浴店、按摩店等，有会员卡充值、
						</br>技师拿计件工资等业务场景。</div>
						<div class="imgtext" style="padding-top:47px;padding-left:45px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left:45px;">1、准确核算技师的计件工资。
						</br>2、实时反映往来款。
						</br>3、及时掌控公司整体的经营盈亏
						</br>状况。
						</br>4、及时掌控会员卡的充值及使用
						</br>情况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag15" @mouseenter="textflagEnter(15)">{{buttontext15}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(15)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bgimg" @mouseenter="buttonEnter(16)" @mouseleave="buttonLeave(16)">
				<div v-if="!flag16">
					<div class="imgsize img16"></div>
					<div class="bgtext">
						<div class="bgtext1">服装行业  >>></div>
						<div class="bgtext2"><span v-html="'\u00a0'"/></span></div>
					</div>
				</div>
				<div v-else>
					<div class="imgsize img_16">
						<div class="imgtext" style="padding-top:68px;padding-left:35px;">适用企业：</div>
						<div class="imgtext2" style="padding-top:4px;padding-left:35px;">服装店。商品规格较多（尺码/颜色/
						</br>款式等），且常用商品需要自动排序
						</br>在前。
						</div>
						<div class="imgtext" style="padding-top:45px;padding-left:35px;">获得哪些收益？</div>
						<div class="imgtext2" style="padding-top:4px;padding-left:35px;">1、实时反映往来款。
						</br>2、及时了解服装的采购、销售及库存
						</br>情况。
						</br>3、及时掌控公司整体的经营盈亏状况。</div>
					</div>
					<div class="bg2text" style="align-items: center;">
						<div class="querybutton" v-if="!textflag16" @mouseenter="textflagEnter(16)">{{buttontext16}}</div>
						<div class="answerbutton" v-else>
							<div class="buttonclass" @mouseleave="textflagLeave(16)">
								<div class="phonetext">合作专线</div>
								<div class="phonetext">13807323918</div>
								<div class="phonetext">（孙老师）</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block3">
			<div class="endbutton" @mouseenter="buttonEnter(17)" @mouseleave="buttonLeave(17)">
				{{buttontext17}}
			</div>
		</div>
	</el-container>
</template>

<script>
  export default {
    name: "solution",
		data() {
			return {
				buttontext1: "免费咨询",
				buttontext2: "免费咨询",
				buttontext3: "免费咨询",
				buttontext4: "免费咨询",
				buttontext5: "免费咨询",
				buttontext6: "免费咨询",
				buttontext7: "免费咨询",
				buttontext8: "免费咨询",
				buttontext9: "免费咨询",
				buttontext10: "免费咨询",
				buttontext11: "免费咨询",
				buttontext12: "免费咨询",
				buttontext13: "免费咨询",
				buttontext14: "免费咨询",
				buttontext15: "免费咨询",
				buttontext16: "免费咨询",
				buttontext17: "更多行业解决方案  >>>",
				flag1: false,
				flag2: false,
				flag3: false,
				flag4: false,
				flag5: false,
				flag6: false,
				flag7: false,
				flag8: false,
				flag9: false,
				flag10: false,
				flag11: false,
				flag12: false,
				flag13: false,
				flag14: false,
				flag15: false,
				flag16: false,
				
				textflag1: false,
				textflag2: false,
				textflag3: false,
				textflag4: false,
				textflag5: false,
				textflag6: false,
				textflag7: false,
				textflag8: false,
				textflag9: false,
				textflag10: false,
				textflag11: false,
				textflag12: false,
				textflag13: false,
				textflag14: false,
				textflag15: false,
				textflag16: false,
			}
		},
		methods:{
			buttonEnter(index) {
				if (index == 17) {
					this.['buttontext' + index] = "合作专线：13807323918（孙老师）";
					return;
				}
				this.['flag' + index] = true;
			},
			buttonLeave(index) {
				if (index == 17) {
					this.['buttontext' + index] = "更多行业解决方案  >>>";
					return;
				}
				this.['flag'+ index] = false;
			},
			
			textflagEnter(index) {
				this.['textflag' + index] = true;
			},
			textflagLeave(index) {
				this.['textflag'+ index] = false;
			},
		}
  }
</script>

<style lang="less" scoped>
	.el-container {
		width: 1920px;
		background-color: #F0F3FA;
		
		.block {
			width: 1920px;
			height: 480px;
			position: absolute;
			
			.text {
				font-size: 50px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				top: 156px;
				left: 210px;
			}
			
			.text1 {
				font-size: 30px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				top: 230px;
				left: 210px;
			}
		}
		
		.text2 {
			font-size: 48px;
			font-family: AlibabaPuHuiTi_2_55_Regular;
			font-weight: normal;
			color: #000000;
			line-height: 36px;
			position: absolute;
			top: 654px;
			left: 665px;
		}
		
		.block2 {
			width: 1500px;
			height: 580px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			position: absolute;
			top: 808px;
			left: 210px;
			
			.bgimg {
				width: 360px;
				height: 580px;
				background-color: #FFF;
				background-repeat: no-repeat;
				border-radius: 11px;
			}
			
			.bgimg:hover {
				box-shadow: 4px 2px 17px 1px rgba(133,133,133,0.28);
			}

			.bgtext {
				width: 272px;
				height: 108px;
				display: flex;
				flex-flow: column;
				align-items: flex-start;
				justify-content: center;
				margin-left: 42px;
				
				.bgtext1 {
					font-size: 24px;
					font-family: AlibabaPuHuiTi_2_55_Regular;
					font-weight: normal;
					color: #000000;
					line-height: 30px;
				}
				
				.bgtext2 {
					font-size: 18px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #535353;
					line-height: 26px;
				}
			}
			
			.bg2text {
				width: 360px;
				height: 108px;
				display: flex;
				flex-flow: column;
				align-items: flex-start;
				justify-content: center;
				border-radius: 0 0 11px 11px;
				
				.querybutton {
					width: 151px;
					height: 38px;
					background: #FF0000;
					border-radius: 19px;
					font-size: 20px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #FFFEFE;
					line-height: 36px;
					text-align: center;
				}
				
				
				.answerbutton {
					width: 360px;
					height: 108px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #FF0000;
					border-radius: 0 0 11px 11px;
					
					.buttonclass {
						width: 140px;
						height: 80px;
						display: flex;
						flex-flow: column;
						align-items: center;
						justify-content: center;
					}
					
					.phonetext {
						font-size: 20px;
						font-family: AlibabaPuHuiTi_2_55_Regular;
						font-weight: normal;
						color: #FFFFFF;
					}
				}
			}
			
			.imgsize {
				width: 360px;
				height: 472px;
				
				.imgtext {
					font-size: 22px;
					font-family: AlibabaPuHuiTi_2_75_SemiBold;
					font-weight: normal;
					color: #FFFFFF;
					line-height: 30px;
					padding: 0 0 0 42px;
				}
				
				.imgtext2 {
					font-size: 18px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #FFFFFF;
					line-height: 26px;
					padding: 0 0 0 42px;
				} 
			}
			
			.img1 {
				background-image: url(../../../assets/image/1bg.png);
			}
			
			.img_1 {
				background-image: url(../../../assets/image/01bg.png);
			}
			
			.img2 {
				background-image: url(../../../assets/image/2bg.png);
			}
			
			.img_2 {
				background-image: url(../../../assets/image/02bg.png);
			}
			
			.img3 {
				background-image: url(../../../assets/image/3bg.png);
			}
			
			.img_3 {
				background-image: url(../../../assets/image/03bg.png);
			}
			
			.img4 {
				background-image: url(../../../assets/image/4bg.png);
			}
			
			.img_4 {
				background-image: url(../../../assets/image/04bg.png);
			}
			
			.img5 {
				background-image: url(../../../assets/image/5bg.png);
			}
			
			.img_5 {
				background-image: url(../../../assets/image/05bg.png);
			}
			
			.img6 {
				background-image: url(../../../assets/image/6bg.png);
			}
			
			.img_6 {
				background-image: url(../../../assets/image/06bg.png);
			}
			
			.img7 {
				background-image: url(../../../assets/image/7bg.png);
			}
			
			.img_7 {
				background-image: url(../../../assets/image/07bg.png);
			}
			
			.img8 {
				background-image: url(../../../assets/image/8bg.png);
			}
			
			.img_8 {
				background-image: url(../../../assets/image/08bg.png);
			}
			
			.img9 {
				background-image: url(../../../assets/image/9bg.png);
			}
			
			.img_9 {
				background-image: url(../../../assets/image/09bg.png);
			}
			
			.img10 {
				background-image: url(../../../assets/image/10bg.png);
			}
			
			.img_10 {
				background-image: url(../../../assets/image/10_bg.png);
			}
			
			.img11 {
				background-image: url(../../../assets/image/11bg.png);
			}
			
			.img_11 {
				background-image: url(../../../assets/image/11_bg.png);
			}
			
			.img12 {
				background-image: url(../../../assets/image/12bg.png);
			}
			
			.img_12 {
				background-image: url(../../../assets/image/12_bg.png);
			}
			
			.img13 {
				background-image: url(../../../assets/image/13bg.png);
			}
			
			.img_13 {
				background-image: url(../../../assets/image/13_bg.png);
			}
			
			.img14 {
				background-image: url(../../../assets/image/14bg.png);
			}
			
			.img_14 {
				background-image: url(../../../assets/image/14_bg.png);
			}
			
			.img15 {
				background-image: url(../../../assets/image/15bg.png);
			}
			
			.img_15 {
				background-image: url(../../../assets/image/15_bg.png);
			}
			
			.img16 {
				background-image: url(../../../assets/image/16bg.png);
			}
			
			.img_16 {
				background-image: url(../../../assets/image/16_bg.png);
			}
		}
	
		.block3 {
			width: 1500px;
			height: 238px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 3201px;
			left: 210px;
			
			.endbutton {
				width: 459px;
				height: 73px;
				background: #FF0000;
				border-radius: 37px;
				font-size: 30px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			
			.endbutton:hover {
				width: 623px;
				height: 73px;
				background: linear-gradient(0deg, #D50000 0%, #FF5400 100%);
				border-radius: 37px;
				font-size: 30px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
			}
		}
	}
</style>